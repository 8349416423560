.book-details.gradient1 {
    background: linear-gradient(to right, #ff7e5f, #feb47b);
}

.book-details.gradient2 {
    background: linear-gradient(to right, #5aaaaa, #2575fc);
}

.book-details.gradient3 {
    background: linear-gradient(to right, #43cea2, #185a9d);
}

.main-background.gradient1 {
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  min-height: 100vh;
}

.main-background.gradient2 {
  background: linear-gradient(to right, #5aaaaa, #2575fc);
  min-height: 100vh;
}

.main-background.gradient3 {
  background: linear-gradient(to right, #43cea2, #185a9d);
  min-height: 100vh;
}

.book-details {
  padding: 20px;
}
.book-details {
  padding: 20px;
  color: #fff; /* Make text white for better contrast */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add text shadow for readability */
}

.book-details a {
  color: #ffeb3b; /* Highlight links with a bright color */
  text-decoration: none;
}

.book-details a:hover {
  text-decoration: underline;
}