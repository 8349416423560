:root, .main {
  --background-gradient: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
}

:root .games {
  --background-gradient: linear-gradient(135deg, #84fab0 0%, #8fd3f4 100%);
}

:root .books {
  --background-gradient: linear-gradient(135deg, #ff9a9e 0%, #fecfef 100%);
}

#root, .main{
  /*border: 2px solid pink;*/
}

body {
  background: var(--background-gradient);
  transition: background 0.3s;
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;
  transform-style: preserve-3d;
  perspective: 1000px;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
}

.card:hover {
  transform: scale(1.1) rotateY(10deg) rotateX(10deg);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.gridContainer {
  justify-content: center;
}